<template>
  <div class="col-sm-12 d-flex justify-content-between">
    <h1 class="font-weight-bolder">
      <slot name="title">
        {{ title }}
      </slot>
    </h1>
    <div>
      <slot name="actions">

      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    },
  },
  data() {
    return {

    };
  },
};
</script>

<style scoped>

</style>
